import React from "react";
import Helmet from 'react-helmet'
import Layout from "../components/layout";
import Card from "../components/card";

var pageTitle = 'Investment Opportunities';
var pageDesc = 'Full List of "It Takes a Titan" campaign investment opportunities.';

export default (props) => (
  <React.Fragment>
  <Layout locationInfo={props.location}>
    <Helmet>
      <title>{pageTitle}</title>
      <body className="funds" />
            
      <meta name="description" content={pageDesc} />

      {/* Schema.org markup for Google+ */}
      <meta itemprop="name" content={pageTitle} />
      <meta itemprop="description" content={pageDesc} />

      {/* Twitter Card data */}
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={pageDesc} />

      {/* Open Graph data */}
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={pageDesc} />
    </Helmet>
    <section style={{backgroundColor: '#EFEDE6', padding: '50px 0',}}>
      <article className="fixed">
        <br /><br /><br />
        <h1 class="center" style={{ margin: '0'}}>Investment Opportunities</h1>
        <Card tag="all" />
      </article>
    </section>
  </Layout>
  </React.Fragment>
);
